export const SliderData = [
    {
        image:
        require("../Images/NC1.png")
    },
    {
        image:
        require("../Images/NC3.png")
    },
    {
        image:
        require("../Images/NC4.png")
    },
    {
        image:
        require("../Images/NC6.png")
    },
    {
        image:
        require("../Images/NC7.png")
    },
    {
        image:
        require("../Images/PA6.png")
    },
    {
        image:
        require("../Images/PA10.png")
    },
    {
        image:
        require("../Images/PA11.png")
    },
    {
        image:
        require("../Images/PA12.png")
    },
    {
        image:
        require("../Images/PA13.png")
    },
    {
        image:
        require("../Images/PA14.png")
    },
    
  ];